<template>
  <div class="project-showcase">
    <Image
      v-if="project.asset?.__typename === 'TeaserImageRecord'"
      cropping-override="slim"
      :image="getImage(project)"
      imgClass="showcase-image"
    />
    <TeaserVideo
      v-else-if="project.asset?.__typename === 'TeaserVideoRecord'"
      :data="project.asset"
      class="video"
    />
    <p v-if="project.title" class="title text-md">
      {{ project.title }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { ProjectShowcaseFragment } from '#gql';
import Image from '~/components/partials/Image.vue';
import TeaserVideo from '../modules/TeaserVideo.vue';
import type { ImageType } from '~/components/partials/Image.vue';

const { locale } = useI18n();

const props = defineProps<{
  project: ProjectShowcaseFragment;
}>();

const getImage = (project: ProjectShowcaseFragment) => {
  // @ts-ignore
  const image = project.asset?.image as ImageType;
  return image;
};
</script>

<style scoped lang="scss">
.project-showcase {
  display: block;
  text-decoration: none;
  color: currentColor;

  :deep(> .image),
  :deep(> .video) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--base-border-radius);
    overflow: hidden;
  }

  > .video {
    height: 100%;
  }

  > .title {
    padding-top: 0.5rem;
  }
}
</style>
<style lang="scss">
.showcase-image {
  aspect-ratio: 1080/1440 !important;
  object-fit: cover;
}
</style>
