<template>
  <div ref="headerElement" class="header-blog">
    <div class="content">
      <h1 v-if="data.title" ref="titleElement" class="title text-4xl">
        {{ data.title }}
      </h1>
    </div>
    <div class="blobs">
      <img class="blob -top" src="/images/header-shape-blue-top.svg" alt="" />
      <img
        class="blob -bottom"
        src="/images/header-shape-blue-bottom.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HeaderBlogFragment } from '#gql';

defineProps<{
  data: HeaderBlogFragment;
}>();

const headerElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);

onMounted(function () {
  document.body.classList.add('-page-ready');
  document.body.classList.add('-ready');
});
</script>

<style lang="scss" scoped>
.header-blog {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;

  > .content {
    height: fit-content;
    width: 100%;
    max-width: 2200px;
    margin: auto;
    z-index: 1;
    > .eyebrow {
      padding-bottom: 1rem;
    }

    @media (max-width: calc(2200px + 10rem)) {
      & {
        margin: 0 var(--base-component-padding-inline);
      }
    }
  }

  .title {
    font-weight: 600;
    max-width: 100ch;
  }

  > .blobs {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    > .blob {
      position: absolute;

      &.-top {
        inset: 0 auto auto 0;
      }

      &.-bottom {
        right: 0;
        bottom: 0;
      }
    }
  }
}

.language-switch {
  z-index: 100;
}
</style>
