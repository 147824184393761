<template>
  <div ref="containerElement" class="projects-simple">
    <h2 v-if="data.title" class="title">
      {{ data.title }}
    </h2>
    <div
      ref="sliderElement"
      class="projects"
      :style="{ '--count': projects.length }"
    >
      <ProjectTeaserSimple
        v-for="project in projects"
        :key="project.id"
        class="teaser"
        :project="project"
        :clickable="data.clickable"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import type { ProjectsSimpleFragment } from '#gql';
import ProjectTeaserSimple from '~/components/unsorted/ProjectTeaserSimple.vue';

const props = defineProps<{
  data: ProjectsSimpleFragment;
}>();

const { isVl } = useViewportSize();

const projects = computed(function () {
  return props.data.projects;
});

const containerElement = ref<HTMLElement | null>(null);
const sliderElement = ref<HTMLElement | null>(null);

const runningOnLeave: (() => void)[] = [];

onMounted(function () {
  if (sliderElement.value && containerElement.value && isVl.value) {
    const animation = sliderAnimation(sliderElement.value);

    runningOnLeave.push(animation.kill);
  }
});

onBeforeUnmount(() => {
  runningOnLeave.forEach((fn) => {
    fn();
  });
});

function sliderAnimation(sliderElement: HTMLElement) {
  const tl = gsap.timeline({
    paused: true,
  });

  tl.to(sliderElement, {
    x: () => -(sliderElement?.scrollWidth - window.innerWidth) + 'px',
    ease: 'none',
    scrollTrigger: {
      trigger: sliderElement,
      end: () => '+=' + sliderElement.offsetWidth,
      scrub: 0.5,
      pin: true,
      invalidateOnRefresh: true,
    },
  });

  return { kill };

  function kill() {
    tl.kill();
  }
}
</script>

<style scoped lang="scss">
.projects-simple {
  padding: 0 var(--base-component-padding-inline);
  max-width: 100vw;
  overflow-x: clip;

  > .title {
    padding-bottom: 3.5rem;
  }

  .projects {
    --count: 1;

    display: flex;
    gap: var(--gap);
    align-items: center;

    @media (--vs) {
      --teaser-width: 75vw;
      --gap: 2.5rem;

      flex-direction: column;
    }

    @media (--vl) {
      --teaser-width: 25vw;
      --gap: 5rem;

      padding-right: 50vw;
      height: 100vh;
      width: calc(25vw + var(--count) * calc(var(--teaser-width) + var(--gap)));
    }

    > .teaser {
      width: var(--teaser-width);
      flex-shrink: 0;
    }
  }
}
</style>
